/**
 * Global Custom Decorations
 */
.border {

  &--default {
    border: 1px solid gray;
  }

}
