/* You can add global styles to this file, and also import other style files */

@import "styles/main";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
* {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
html,
body {
  height: 100%;
}
// body { margin: 0; font-family: 'Poppins', "Helvetica Neue", sans-serif; }
thead tr {
  background-color: #7cc707 !important;
  color: #fff;
}
td {
  vertical-align: top !important;
  text-align: center !important;
  min-width: 90px;
  max-width: 250px;
  overflow-wrap: break-word;
}

#navbar {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 15px;
}
#table_div {
  display: block;
  margin-top: 10px;
  padding: 0;
}

.btn-danger {
  background-color: #d00140 !important;
  border-color: #d00140 !important;
}

button {
  transition: 0.3s ease-out !important;
}
button:hover {
  transform: translate(0, -3px);
}
label {
  margin-top: 10px;
  font-family: "Poppins", sans-serif;
}
.field-loader {
  height: 70px;
  padding: 10px 0px;
}
.field-loader img {
  height: 100%;
}
::-webkit-scrollbar {
  width: 8px;
  margin-top: 5px !important;
}
::-webkit-scrollbar-track {
  background-color: #d3d3d3;
  border: 5px;
  box-shadow: inset 0 0 5px rgba(245, 245, 245, 0.8);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(124, 199, 7, 0.8);
  border-radius: 5px;
}
#table_div.fixed {
  overflow: hidden;
}
#table_div.scroll {
  overflow-x: auto;
}
#table_div::-webkit-scrollbar {
  height: 5px;
}
#table_div::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border: 0 5px 5px 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
}
#table_div::-webkit-scrollbar-thumb {
  background-color: #d00140;
  border-radius: 5px 0 0 5px;
}
// #table_div::-webkit-scrollbar-thumb:hover {
//   background-color: #7cc707;
//   height: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #d00140;
// }

.inner-content::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0 !important ;
}
.inner-content::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0 !important ;
}
.btn:focus {
  box-shadow: 0 0 0 0 !important;
}

button:focus {
  box-shadow: 0 0 0 0 !important;
}
.excelStyles {
  cursor: pointer;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: #ced4da !important;
}
.form-control:focus {
  border-color: #ced4da !important;
}

// ::ng-deep
.ng-dropdown-panel {
  box-shadow: 0 0 2px;
}

// ::ng-deep
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  background-color: #f3f3f3;
}
// ::ng-deep
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: white;
  // height: 17rem;
  height: 2.5rem !important;
  padding: 10px 35px !important;
}

// ::ng-deep

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: #7cc707;
  color: #fff;
  padding: 2px 4px;
  margin: -4px 2px 0;
  border-radius: 2px;
  .ng-value-icon {
    margin: 0 3px;
    font-size: 15px;
  }
}

// ::ng-deep

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #7cc707;
  color: white;
}

// ::ng-deep
.ng-select .ng-select-container {
  overflow: visible !important;
}
// ::ng-deep
.ng-select .ng-clear-wrapper {
  width: 7px !important;
}

// ::ng-deep
.ng-select .ng-has-value .ng-placeholder {
  display: none;
}
// ::ng-deep
.ng2-tag-input {
  margin: -6px 0 0 0;
  border: none !important;
  min-height: none !important;
  padding: 2px 0 0 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
// ::ng-deep
tag {
  border-radius: 5px !important;
  padding: 0 0.7rem;
  background-color: #7cc707 !important;
  color: #fff !important;
  line-height: 27px !important;
  height: 27px !important;
  display: inline-block !important;
}
// ::ng-deep
.ng2-tag-input__text-input {
  padding: 0px 0.5rem 0 0.5rem !important;
  line-height: 20px !important;
  height: 30px !important;
  width: 100%;
}
// ::ng-deep
delete-icon {
  filter: invert(100%) sepia(0%) saturate(3286%) hue-rotate(288deg)
    brightness(208%) contrast(104%) !important ;
}
// ::ng-deep
delete-icon svg {
  height: 27px !important;
}
// ::ng-deep
.ng2-tags-container {
  display: block !important;
}

// ::ng-deep .ng-select div {
// }
.trigger:hover {
  color: #7cc707 !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #7cc707 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
  border-color: #7cc707 !important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #7cc707 !important;
}
.ant-menu-light .ant-menu-submenu-active {
  color: #7cc707 !important;
}
.form-control:focus {
  box-shadow: none !important;
}
.ant-picker-content th {
  color: white !important;
}

.ant-picker-header-view button:hover {
  color: #7cc707 !important;
}
.ant-picker-input > input {
  font-size: 13px !important;
}

.swal2-title {
  font-size: 1.35rem !important;
}
.heading {
  height: 50px;
  text-align: center;
  background-color: #7cc707;
  color: #fff;
  font-size: 1.3rem;
  width: 100%;
  padding-top: 13px;
  margin-bottom: 2px;
}

.table-icon {
  font-size: 0px;
  cursor: pointer;
  display: inline-block;
}

.green {
  color: #7cc707;
}
.pink {
  color: #d00140;
}
